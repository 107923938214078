@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
body{
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0;
    background-color: #f2f2f2;
}
button {
    font-weight: 600 !important;
    opacity: 1 !important;
}
h1, h2, h3, h4 {
    font-weight: 500 !important;
}
.mt-50{
    margin-top: 50px;
}
.mb-20{
    margin-bottom: 20px;
}
.pb-30{
    padding-bottom: 30px;
}
.btn-modal {
    margin-top: 25px !important;
    float: right !important;
}
.header-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    background-color: #10313A;
}
.btn-theme{
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    font-family: 'Open Sans', sans-serif;
}
.ag-theme-alpine .ag-header-row {
    font-weight: 500 !important;
}
.MuiButtonBase-root-MuiTab-root{
    opacity: 1 !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
}
.menu-item {
    padding: 0 6px;
    color:  rgba(255, 255, 255, 0.7);
    text-decoration: none;
}
.menu-item svg {
    fill: rgba(255, 255, 255, 0.7);;
}
.menu-item.active {
    color: #EEFF38 !important;
}
.menu-item.active svg {
    fill: #EEFF38 !important;
}
.grid-tabs-container {
    display: flex;
    height: 33px;
    /* background-color: rgb(242, 242, 242); */
    position: relative;
    padding-left: 30px;
    border-bottom: 6px solid rgb(0, 62, 155);
}
.tab-header.active {
    color: #003e9b !important;
    border-bottom: 2px solid #003e9b !important;
}
.title-nav {
    min-height: 29px;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 10px;
}
.tab-header{
    width: auto !important;
    padding: 6px 16px 6px !important;
    min-width: 0 !important;
    font-weight: 600 !important;
    text-transform: none !important;
    border-bottom-width: 0 !important;
    color: #1976d2 !important;
    font-size: 16px !important;
    text-decoration: none !important;
}
.tab-custom{
    width: 84px !important;
    border: solid 1px #d5d5d5 !important;
    padding: 6px 12px 6px !important;
    min-width: 120px !important;
    min-height: 33px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    border-bottom-width: 0 !important;
    color: #666363 !important;
    font-size: 16px !important;
}
.Mui-selected.tab-custom {
    color: #fff !important;
    border-color: #003E9B !important;
    background-color: #003E9B !important;
}
.MuiTabs-indicator {
    bottom: 10px !important;
    background-color: #003e9b !important;
}
.btn-cell-dropdown {
    background-color: #d4d4d4 !important;
    border-radius: 0 !important;
    margin-bottom: 4px !important;
    color: #000 !important;
    width: 100% !important;
}
.ag-theme-alpine {
    --ag-font-size: 14px !important;
    --ag-font-family: 'Open Sans' !important;
    --ag-font-weight: 500 !important;
    /* --ag-border-color: #b42d2d !important; */
    
}
.MuiListSubheader-root{
    font-size: 14px !important;
    font-weight: 600 !important;
}

.tab-spacing{
    padding: 0 !important;
}
.action-buttons {
    float: right;
}
.action-buttons button {
    padding: 12px 10px;
    margin-bottom: 10px
}
.setting-nav-menu ul {
    list-style: none;
    padding-left: 0;
}
.setting-nav-menu li.nav-item a {
    text-decoration: none;
    font-size: 16px;
    color: #222628;
    padding: 13px 12px;
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
.setting-nav-menu li {
    padding: 10px 12px;
}
.setting-nav-menu li:hover {
    background-color: #d5d5d5;
}
.setting-nav-menu li.nav-item span{
    float:right;
    margin-right: 15px;
} 
.setting-nav-menu li.nav-item span svg{
    width: 16px !important;
    color:#767676;
}
.setting-nav-menu li.nav-item.active{
    background-color: #d5d5d5;
}
.ag-cell-wrapper svg{
    width: 16px;
    height: 16px;
    /* color: #949a9f; */
    vertical-align: middle;
}
.ag-header-cell, .ag-header-group-cell {
    border-right: 1px solid #d5d5d5 !important;
}
.ag-ltr .ag-cell {
    border-right-color: #d5d5d5 !important;
}
.ag-ltr .ag-cell:last-child {
    border-right-width: 0px solid !important;
  }
.btn-bottom svg {
    vertical-align: middle;
    margin-right: 5px;
}

.btn-bottom button {
    font-weight: 600 !important;
}
.MuiFormControl-root-MuiTextField-root{
    width: 100% !important;

}
.w-100{
    width: 100% !important;
}
.pr-50 {
    padding-right: 50px !important;
}
.pl-50 {
    padding-left: 50px !important;
}
.card-header {
    padding: 18px 25px 0px;
}
.card-header p {
    font-size: 14px;
    margin-bottom: 0 !important;
    margin-top: 0;
}
.card-header h4 {
    font-size: 18px;
    font-weight: 700 !important;
    color: #000;
    line-height: 24px;
    margin-top: 2px;
    margin-bottom: 0px;
    border-bottom: 4px solid rgb(0 62 155 / 50%);
    padding-bottom: 15px;
}
.card-body {
    padding: 10px 25px 10px;
}
.form-custom {
    padding: 10px 15px !important;
}
.form-custom .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
}
.form-custom .MuiInputBase-root-MuiInput-root:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}